var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-table", {
    staticClass: "gx-table-responsive",
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.billing,
      loading: _vm.loading,
    },
    scopedSlots: _vm._u([
      {
        key: "date",
        fn: function (text) {
          return [_vm._v("\n    " + _vm._s(_vm.nUnixTime(text)) + "\n  ")]
        },
      },
      {
        key: "status",
        fn: function (text) {
          return [
            _c("span", { staticStyle: { "text-transform": "capitalize" } }, [
              _vm._v(_vm._s(text)),
            ]),
          ]
        },
      },
      {
        key: "amount_due",
        fn: function (text) {
          return [
            _vm._v("\n    €" + _vm._s(_vm.formatStripeMoney(text)) + "\n  "),
          ]
        },
      },
      {
        key: "amount_paid",
        fn: function (text) {
          return [
            _vm._v("\n    €" + _vm._s(_vm.formatStripeMoney(text)) + "\n  "),
          ]
        },
      },
      {
        key: "operation",
        fn: function (text, record) {
          return [
            _c(
              "div",
              { staticClass: "editable-row-operations gx-text-right" },
              [
                record.invoice_pdf
                  ? _c(
                      "a",
                      { attrs: { href: record.invoice_pdf } },
                      [
                        _c(
                          "a-button",
                          {
                            staticClass: "gx-mb-0 gx-mr-2",
                            attrs: { type: "primary" },
                          },
                          [_vm._v("\n          Download\n        ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                record.status === "open"
                  ? _c(
                      "a",
                      { attrs: { href: record.hosted_invoice_url } },
                      [
                        _c(
                          "a-button",
                          { staticClass: "gx-btn-cyan gx-mb-0 gx-mr-2" },
                          [_vm._v("\n          Pay Invoice\n        ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }